import {any, contains} from 'ramda';
import {
	salesRoleTypes,
	subcontractorRoleSlugs,
	thirdPartyRoleSlugs,
	roleSlugsWithIssueCreation,
	salesmanAppAdminRoleSlugs,
} from 'constants/perms';

const appPermPrefix = 'access.';

/**
 * Check if user has a permission
 * @param {Object} user
 * @param {String} permission
 * @returns {Boolean}
 */
const userHasPermission = (user, permission) =>
	!!user.permissions.find(p => p.slug === permission);

export const userAppPermissions = user => {
	const perms = new Set();
	user.permissions.forEach(perm => {
		if (perm.slug.startsWith(appPermPrefix)) {
			perms.add(perm.slug.substring(appPermPrefix.length));
		}
	});

	return perms;
};

export const salesLeaderRoleSlugs = ['myyntijohtaja', 'aluemyyntijohtaja'];

// TODO: figure out a proper permission tiering system?

// TODO: the teleUser vs salesmanUser isn't the most logical now - anybody with just tele roles is a teleman, anybody with some salesman roles is a salesman, and salesmen are "higher" ranked than telemen. would require changing the API to improve
export const isTeleUser = user => user.roles.every(r => !r.type || r.type === 'teleman');

// PERM: userType
export const isSalesUser = user => !!user.roles.find(r => salesRoleTypes.has(r.type));

// PERM: role.salesmanager-user
export const isSalesmanagerUser = user =>
	!!user.roles.find(r => r.type === 'salesmanager');

export const isSalesmanUser = user =>
	!!user.roles.find(r => r.type === 'salesman') && !isSalesmanagerUser(user);

export const isSalesLeader = user =>
	any(r => contains(r.slug, salesLeaderRoleSlugs), user.roles);

// PERM: not-sub-contractor
export const isSubcontractorUser = user =>
	!!user.roles.find(r => subcontractorRoleSlugs.has(r.slug));

// PERM: not-third-party
export const isThirdPartyUser = user =>
	!!user.roles.find(r => thirdPartyRoleSlugs.has(r.slug));

// PERM: map-create-issues
export const userCanCreateIssues = user =>
	!!user.roles.find(r => roleSlugsWithIssueCreation.has(r.slug));

// PERM: customerOrganization
export const isCustomerOrganization = activeOrganization =>
	activeOrganization && activeOrganization.meta.customerOrganization;

// PERM: create-time-entries
export const userCanCreateTimeEntries = user =>
	!!user.roles.find(r => r.type === 'd2dman' || r.type === 'teleman');

// sales app visit cancelling permissions
export const userCanCancelVisit = ({user, calendarResource}) => {
	const cancelAllVisitsPerm = !!user.permissions.find(
		p => p.slug === 'calendar-resources.cancel-all-visits',
	);

	const canCancelOwnVisit =
		!!user.permissions.find(p => p.slug === 'calendar-resources.cancel-own-visits') &&
		[calendarResource.reserverId, calendarResource.salesmanId].includes(user.id);

	const salesManagerRoles = ['myyntipaeaellikkoe'];
	const isSalesManager = user =>
		any(r => contains(r.slug, salesManagerRoles), user.roles);

	const source = calendarResource.reservationSource;

	//users with "cancel-all-visits" perm can cancel all visits
	//salesmanagers can cancel visits not reserved by d2dman or teleman
	//salesman can cancel visits he has reserved
	const canCancelVisit =
		cancelAllVisitsPerm ||
		canCancelOwnVisit ||
		(isSalesManager(user) && !(source === 'teleman' || source === 'd2dman'));

	return canCancelVisit;
};

export const userCanEditVisit = ({user, calendarResource}) => {
	const completed = calendarResource.type === 'completed';
	return (
		!completed &&
		!!user.permissions.find(p => p.slug === 'calendar-resources.update-all-visits')
	);
};

export const userCanAddCalendarResourcesToAnyTeam = user =>
	!!user.permissions.find(
		p => p.slug === 'calendar-resources.add-calendar-resources-to-any-team',
	);

export const userCanAddFreeTimes = user =>
	!!user.permissions.find(p => p.slug === 'calendar-resources.can-add-free-times');

export const canViewCalendarResourceSource = user =>
	!!user.permissions.find(p => p.slug === 'calendar-resources.can-view-source');

// PERM: role.pilot
export const isPilotUser = user => !!user.roles.find(r => r.slug === 'pilotoija');

// PERM: salesman-app-organization-stats
export const isSalesmanAppAdmin = user =>
	!!user.roles.find(r => salesmanAppAdminRoleSlugs.has(r.slug));

// PERM: delete-buildings
export const userCanDeleteBuildings = user => isSalesmanagerUser(user);

// PERM: admin-user-management
export const isAdminUser = user => !!user.roles.find(r => r.slug === 'admin');

// PERM: marketing-lead-handler
export const isMarketingLeadHandler = user => user.meta && user.meta.marketingLeadHandler;

export const showAllMarketingLeads = user =>
	!!user.permissions.find(p => p.slug === 'leads.show-all-marketing-leads') ||
	!!user.permissions.find(p => p.slug === 'leads.marketing-lead-handler');

export const showActiveOrganizationMarketingLeads = user =>
	!!user.permissions.find(p => p.slug === 'leads.show-own-organization-marketing-leads');

export const showSalesmanagerLeads = user =>
	!!user.permissions.find(p => p.slug === 'leads.show-own-salesmanager-leads');

export const showAllSalesmanagerLeads = user =>
	!!user.permissions.find(p => p.slug === 'leads.show-all-salesmanager-leads');

export const showOwnOrgSalesmanagerLeads = user =>
	!!user.permissions.find(
		p => p.slug === 'leads.show-own-organization-salesmanager-leads',
	);

export const showFacebookLeadsUpload = user =>
	!!user.permissions.find(p => p.slug === 'leads.facebookExcelImport');

export const hasTimeEntriesCrud = user =>
	!!user.permissions.find(p => p.slug === 'timeEntries.crud');

export const usersAppRolesPageAccess = user =>
	!!user.permissions.find(p => p.slug === 'view.users.roles');

export const usersAppOrganizationsPageAccess = user =>
	!!user.permissions.find(p => p.slug === 'view.users.organizations');

export const salesmanAppTeamAreaTeamSelect = user =>
	!!user.permissions.find(p => p.slug === 'salesman.team-areas.show-team-select');

export const marketingAppSettingsPageAccess = user =>
	!!user.permissions.find(p => p.slug === 'view.marketing.settings');

export const showBuildingEncounters = user =>
	!!user.permissions.find(p => p.slug === 'building.show-encounters');

export const usersAppUserPermissionAccess = user =>
	!!user.permissions.find(p => p.slug === 'users.users.permission-handling');

export const buildingsAppRemovalRequestsPageAccess = user =>
	!!user.permissions.find(p => p.slug === 'view.buildings.removal-requests');

export const buildingsAppShowCsvImporterAccess = user =>
	!!user.permissions.find(p => p.slug === 'building.show-csv-importer');

export const userCanImportClients = user =>
	!!user.permissions.find(p => p.slug === 'csv-import.import-clients');

export const userCanImportBuildings = user =>
	!!user.permissions.find(p => p.slug === 'csv-import.import-buildings');

export const buildingsAppDeleteCsvImportClientsAccess = user =>
	!!user.permissions.find(p => p.slug === 'building.delete-imported-clients');

export const showBuildingComments = user =>
	!!user.permissions.find(p => p.slug === 'building.show-comments');

export const calendarShowTeamUsers = user =>
	!!user.permissions.find(p => p.slug === 'calendar.show-team-users');

export const calendarShowOrganizationSalesmen = user =>
	!!user.permissions.find(p => p.slug === 'calendar.show-organization-salesmen');

export const calendarShowOrganizationTelemen = user =>
	!!user.permissions.find(p => p.slug === 'calendar.show-organization-telemen');

export const calendarCreateCalendarEventForAnyUser = user =>
	!!user.permissions.find(p => p.slug === 'calendar.create-calendar-event-for-any-user');

export const filesManagement = user =>
	!!user.permissions.find(p => p.slug === 'files.management');

export const showProfinderListsManagement = user =>
	!!user.permissions.find(p => p.slug === 'profinder.lists');

export const canEditBonusDate = ({user, calendarResource}) => {
	// sales user can edit bonus dates reserved by self
	if (isSalesUser(user) && calendarResource.reserverId === user.id) {
		return true;
	}

	// telepaeaellikkoe can edit bonus dates reserved by teleman
	if (
		!!user.roles.find(r => r.slug === 'telepaeaellikkoe') &&
		calendarResource.reservationSource === 'teleman'
	) {
		return true;
	}

	return false;
};

export const viewLockedTeamCalendar = user =>
	!!user.permissions.find(p => p.slug === 'team-calendar.view-locked-calendar');

export const canAccessToAdditionalTeamsInTeamCalendar = user =>
	!!user.permissions.find(
		p => p.slug === 'team-calendar.calendar.access-to-additional-teams',
	);

export const canShowEncounterReason = user =>
	userHasPermission(user, 'encounters.show-reason');

export const canSendSmsMessage = user =>
	!!user.permissions.find(p => p.slug === 'sms.send-message');

export const canEditSmsMessageField = user =>
	!!user.permissions.find(p => p.slug === 'sms.edit-message-field');

export const canSendCustomMessage = user =>
	!!user.permissions.find(p => p.slug === 'sms.send-custom-message');

export const userCanShowDashboardOrganizations = user =>
	!!user.permissions.find(p => p.slug === 'dashboard.show-all-organizations');

export const userUseNewDashboard = user =>
	!!user.permissions.find(p => p.slug === 'dashboard.use-new-dashboard');

export const canReadEncounterable = user =>
	!!user.permissions.find(p => p.slug === 'encounterable.read');

export const canReadTeamsEncounterable = user =>
	!!user.permissions.find(p => p.slug === 'encounterable.read-teams');

export const canReadAnyEncounterable = user =>
	!!user.permissions.find(p => p.slug === 'encounterable.read-all');

export const canViewEncounterableListViewAdditionalFilters = user =>
	!!user.permissions.find(
		p => p.slug === 'encounterable-list-view.view-additional-filters',
	);

// budget

export const userCanShowBudget = user =>
	!!user.permissions.find(p => p.slug === 'budget.show');

export const userCanEditBudget = user =>
	!!user.permissions.find(p => p.slug === 'budget.edit');

// lists

export const userCanSaveList = user =>
	!!user.permissions.find(p => p.slug === 'lists.save');
